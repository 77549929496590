import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline'
import React from 'react'

const features = [
  {
    name: 'Submit your design',
    imgPath: "/assets/tshirt.png",
    description: 'Let us know what kind of merch you want and what you want it to look like.',
  },
  {
    name: 'Your merch is produced',
    imgPath: "/assets/production.png",
    description: 'We take your request and create beautiful merch from it. Once you approve, we are ready to launch.',
  },
  {
    name: 'Sell your merch',
    imgPath: "/assets/shopping.png",
    description: 'Your followers are able to purchase your merch on our site. We take care of all the details like shipping, exchanges, returns, etc.',
  },
  {
    name: 'Get paid',
    imgPath: "/assets/paid.png",
    description: 'Every month, you get paid for the proceeds of your sales.',
  }
]

export default function HowItWorks() {
  return (
    <div id="HowItWorks" className="bg-gray-600">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mt-2 text-3xl font-extrabold text-white sm:text-4xl">How it works</p>
          <p className="mt-4 text-lg text-indigo-200">
            Our process puts you in control. We take care of all the details so you focus on your talents.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-1 text-center sm:gap-x-6 sm:gap-y-12 lg:grid-cols-11 ">
          {features.map((feature, index) => (
            <React.Fragment key={feature.name}>
                <div className="relative col-span-2">
                <dt>
                    <img className='object-contain md:object-scale-down' src={feature.imgPath}/>
                    <p className="text-lg pt-2 leading-6 font-medium text-indigo-200">{feature.name}</p>
                </dt>
                <dd className="text-base text-white">{feature.description}</dd>
                
                </div>
                {index === (features.length -1)? 
                (<></>)
                :(<div className='flex items-center justify-center'>
                    <div className='flex hidden lg:block justify-center'><ChevronRightIcon className='stroke-indigo-200 h-12 w-12' /></div>
                    <div className='flex block lg:hidden justify-center'><ChevronDownIcon className='stroke-indigo-200 h-12 w-12' /></div>
                </div>)
                }
            </React.Fragment>
          ))}
        </dl>
      </div>
    </div>
  )
}
