import { Disclosure} from '@headlessui/react'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import NavRoutes from './nav_routes'


export function Navbar() {
  const location = useLocation()

  const renderMenuItems = (isMobile) => {
    if(isMobile){
        return(
            <>
            {
                NavRoutes.main.map((item)=>{
                   return( 
                    <Disclosure.Button
                        as="a"
                        key={`mobile_${item.path}`}
                        href={window.location.origin + item.path }
                        className={`${item.path === location.pathname? "bg-gray-900 text-white": "text-gray-300 hover:bg-gray-700 hover:text-white"} block px-3 py-2 rounded-md text-base font-medium`}
                    >
                        {item.name}
                    </Disclosure.Button>
                    )
                })
            }
            </>
        )
    }   
    return(
        <>
        {
            NavRoutes.main.map((item)=>{
               return( 
                <HashLink
                    key={item.path}
                    smooth
                    to={item.path}
                    className={`${item.path === location.pathname? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white" } px-3 py-2 rounded-md text-sm font-medium`}
                >
                    {item.name}
                </HashLink>
               )
            })
        }
        </>
    )
}

  return (
    <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-12 w-auto"
                    src="/only-merch-logo.png"
                    alt="Only Merch logo"
                  />
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                    {renderMenuItems(open)}
                  </div>
                </div>
              </div>
              <div className="-mr-2 flex sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              {renderMenuItems(open)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
export default Navbar
