import Banner from "./components/banner"
import Stats from "./components/stats"
import Screenshot from "./components/screenshot"
import Features from "./components/features"
import ContactForm from "./components/contact_form"
import HowItWorks from "./components/how_it_works"

export default function Home(){
    return (
        <>
            <Banner />
            {/* <Stats /> */}
            {/* <Screenshot /> */}
            <Features />
            <HowItWorks />
            <ContactForm />
        </>
        
    )
}