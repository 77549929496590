import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/scroll_to_top';
import Layout from './layout';
import Home from './pages/home/home';
import Test from './pages/test/test';
import NotFound from './not_found';


function App() {
    return (
        <div className="App">
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Home  />} />
                    <Route path="test" element={<Test />} />
                    <Route path="*" element={<NotFound/>}/>
                  </Route>    
              </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;