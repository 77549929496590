import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'High quality merch',
    description: 'We only work with the highest quality materials. Your followers will be impressed.',
  },
  {
    name: 'Samples included',
    description: 'You approve the merch before it gets produced. You will love your merch.',
  },
  {
    name: 'Dedicated account manager',
    description: 'All of our clients have a dedicated account manager. No more being unsure of who to contact when you have a question.',
  },
  {
    name: 'Seamless Fulfillment',
    description: 'We take care of all the logistics. We will store your merch and fulfill all the orders.',
  },
  {
    name: 'Smart restocking',
    description: 'Never run out of sizes or colors again. Our team works hard to make sure your merch is always available.',
  },
  {
    name: 'Easy returns and exchanges',
    description: 'If an order needs to be refunded or exchanged, we got that too. Customer service is our top priority.',
  },
  {
    name: 'Beautiful storefront',
    description: 'Our engineers work hard to make sure your brand is presented in a beutiful, cutting edge eCommerce storefront.',
  },
  {
    name: 'Easy payment',
    description: 'You set your price and keep app the profit. Getting paid has never been easier. We send you a transparent, detailed monthly statement with your sales and proceeds.',
  }
]

export default function Features() {
  return (
    <div id="ProductFeatures" className="bg-gradient-to-b from-gray-700 to-gray-600">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mt-2 text-3xl font-extrabold text-white sm:text-4xl">All-in-one solution</p>
          <p className="mt-4 text-lg text-indigo-200">
          Selling your merch has never been easier. You are treated like royalty every step of the way.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-indigo-200">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-white">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
