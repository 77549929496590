import { useState } from 'react';
import { BadgeCheckIcon } from '@heroicons/react/solid'

export default function ContactForm() {
    const [ firstName, setFirstName ] = useState("")
    const [ lastName, setLasttName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ phoneNumber, setPhoneNumber ] = useState("")
    const [ message, setMessage ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ submitted, setSubmitted ] = useState(false)
    
    const submitForm = async (data)=>{
      try{
        setLoading(true)
        const url = 'https://0fhn9o9jz9.execute-api.us-east-1.amazonaws.com/contactSubmit'
        const config = {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(data)
        }
        await fetch(url, config)
        setLoading(false)
        setSubmitted(true)
      }catch(e){
        setLoading(false)
        setSubmitted(true)
      }
      
    }
    const handleSubmit = (e)=>{
      e.preventDefault()
      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        message,
      }
      return submitForm(data).then((res)=>{
        
      }).catch((e)=>{
        
      })
    }
  return (
    <div id="GetStarted" className="bg-white">

      <main className="overflow-hidden">
        {/* Contact section */}
        <section className="relative bg-gray-600" aria-labelledby="contact-heading">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact form */}
                <div className="justify-center py-10 px-6 sm:px-10 lg:col-span-3 xl:p-12">
                    <p className="mt-2 text-3xl text-center font-extrabold text-white sm:text-4xl">Let's work together</p>
                    {submitted? 
                    (
                        <div className='py-20 flex items-center justify-center text-center'>
                            <BadgeCheckIcon className='text-green-500 h-20 w-20' />
                            <span className='text-white'>Thank you for submitting your inquiry. We will be in touch shortly</span>
                        </div>
                    ):(
                        <form onSubmit={(e)=>handleSubmit(e)} className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                            <div>
                                <label htmlFor="first-name" className="block text-sm font-medium text-indigo-200">
                                First name
                                </label>
                                <div className="mt-1">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    required
                                    value={firstName}
                                    onChange={(e)=>setFirstName(e.target.value)}
                                    autoComplete="given-name"
                                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-200 focus:border-indigo-200 border-warm-gray-300 rounded-md"
                                />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="last-name" className="block text-sm font-medium text-indigo-200">
                                Last name
                                </label>
                                <div className="mt-1">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    value={lastName}
                                    required
                                    onChange={(e)=>setLasttName(e.target.value)}
                                    autoComplete="family-name"
                                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-200 focus:border-indigo-200 border-warm-gray-300 rounded-md"
                                />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-indigo-200">
                                Email
                                </label>
                                <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    required
                                    onChange={(e)=>setEmail(e.target.value)}
                                    autoComplete="email"
                                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-200 focus:border-indigo-200 border-warm-gray-300 rounded-md"
                                />
                                </div>
                            </div>
                            <div>
                                <div className="flex justify-between">
                                <label htmlFor="phone" className="block text-sm font-medium text-indigo-200">
                                    Phone
                                </label>
                                <span id="phone-optional" className="text-sm text-indigo-200">
                                    Optional
                                </span>
                                </div>
                                <div className="mt-1">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={phoneNumber}
                                    onChange={(e)=>setPhoneNumber(e.target.value)}
                                    autoComplete="tel"
                                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-200 focus:border-indigo-200 border-warm-gray-300 rounded-md"
                                    aria-describedby="phone-optional"
                                />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="flex justify-between">
                                <label htmlFor="message" className="block text-sm font-medium text-indigo-200">
                                    Message
                                </label>
                                <span id="message-max" className="text-sm text-indigo-200">
                                    Max. 500 characters
                                </span>
                                </div>
                                <div className="mt-1">
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={4}
                                    value={message}
                                    required
                                    onChange={(e)=>setMessage(e.target.value)}
                                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-indigo-200 focus:border-indigo-200 border border-warm-gray-300 rounded-md"
                                    aria-describedby="message-max"
                                />
                                </div>
                            </div>
                            <div className="sm:col-span-2 sm:flex sm:justify-end">
                                <button
                                type="submit"
                                className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200 sm:w-auto"
                                >
                                {loading?
                                    (<svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>):
                                    (<></>)
                                    }
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
