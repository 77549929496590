import { Outlet } from "react-router-dom";
import Footer from "./components/footer"
import Navbar from './components/navbar'

function Layout() {
    return (
        <>
            <Navbar />
            <div>
            <Outlet />
            </div>
            <Footer />
        </>
    );
}

export default Layout;
